import { createTheme } from '@material-ui/core/styles'

// Create a theme instance.
const theme = createTheme({
    palette: {
        primary: {
            main: '#000000',
            contrastText: '#FFFFFF'
        },
        secondary: {
            main: '#FFFFFF',
            contrastText: '#000000'
        },
        background: {
            default: '#FFFFFF'
        },
        red: {
            main: '#FF4040',
            light: '#d8aa8a'
        },
        gray: {
            main: '#858585',
            light: '#E0E0E0',
            lighter: '#f8f8f8'
        },
        imgOverlay: {
            main: 'rgba(0, 0, 0, 0.04)'
        },
        divider: 'rgba(0, 0, 0, 0.12)'
    },

    breakpoints: {
        values: {
            xs: 0,
            s: 440,
            sm: 600,
            m: 770,
            md: 960,
            l: 1025,
            lg: 1280,
            xl: 1440
        }
    },

    typography: {
        fontFamily: [
            'DM Sans',
            'FuturaPT'
        ].join(',')
    },

    props: {
        MuiButtonBase: {
            disableRipple: true
        },
        MuiBadge: {
            overlap: 'rectangular'
        }
    },
    overrides: {
        MuiAppBar: {
            colorTransparent: {
                color: 'white'
            }
        },
        MuiButton: {
            root: {
                borderRadius: 4
            }
        },
        MuiTypography: {
            h1: {
                fontSize: 74,
                letterSpacing: '0.05em',
                lineHeight: '92.5px',
                fontWeight: 400,
                fontFamily: 'FuturaPT, DM Sans'
            },
            h2: {
                fontSize: 50,
                letterSpacing: '0.05em',
                lineHeight: '62.5px',
                fontWeight: 400,
                fontFamily: 'FuturaPT, DM Sans'
            },
            h3: {
                fontSize: 40,
                letterSpacing: '0.05em',
                lineHeight: '50px',
                fontWeight: 400,
                fontFamily: 'FuturaPT, DM Sans'
            },
            h4: {
                fontSize: 30,
                letterSpacing: '0.05em',
                lineHeight: '37.5px',
                fontWeight: 400,
                fontFamily: 'FuturaPT, DM Sans'
            },
            h5: {
                fontSize: 22,
                letterSpacing: '0.1em',
                lineHeight: '37.5px',
                fontWeight: 400,
                fontFamily: 'FuturaPT, DM Sans'
            },
            // Title light
            subtitle1: {
                fontSize: 16,
                letterSpacing: '0.1em',
                lineHeight: '20px',
                fontWeight: 400,
                textTransform: 'uppercase',
                fontFamily: 'FuturaPT, DM Sans'
            },
            // Title strong
            subtitle2: {
                fontSize: 16,
                letterSpacing: '0.1em',
                lineHeight: '20px',
                fontWeight: 450,
                textTransform: 'uppercase',
                fontFamily: 'FuturaPT, DM Sans'
            },
            // Paragraph L
            body1: {
                fontSize: 16,
                letterSpacing: '0.05em',
                lineHeight: '20.8px',
                fontWeight: 400,
                fontFamily: 'DM Sans'
            },
            // Paragraph M
            body2: {
                fontSize: 14,
                letterSpacing: '0.05em',
                lineHeight: '17.5px',
                fontWeight: 400,
                fontFamily: 'DM Sans'
            },
            caption: {
                color: '#858585',
                fontSize: 14,
                letterSpacing: '0.05em',
                lineHeight: '17.5px',
                fontWeight: 400,
                fontFamily: 'DM Sans'
            }
        },
        MuiCircularProgress: {
            root: {
                margin: '0 auto',
                display: 'block'
            }
        }
    }
})

export default theme
